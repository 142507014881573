import { createTheme } from "@mui/material/styles";
import Futura_PT_Book from "../fonts/Futura_PT_Book.ttf";

const PLRed = "#C60C30";
const PLBlack = "#1C1B1C";
const PLDkgGrey = "#333333";
const PLGreyNew = "#282728";
const PLWhite = "#E2E3E4";
const PLWhiteNew = "#D4D5D6";
const PLLightGrey = "#444444";

const darkTheme = createTheme({
	palette: {
		primary: {
			main: PLRed,
			contrastText: PLWhite,
			footerIcon: '#ffffff',
		},
		secondary: {
			main: PLWhiteNew,
			contrastText: PLDkgGrey,
			cardHover: PLLightGrey,
			rowHover: '#333'
		},
		text: {
			primary: PLWhite,
			tabHover: 'white',
			tabFocus: 'white',
		},
		background: {
			default: PLBlack,
			paper: PLGreyNew,
		},
	},
	typography: {
		fontFamily: "Futura",
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				'@font-face': {
					fontFamily: 'Futura',
					src: `local('Futura'), local('Futura PT Book'), url(${Futura_PT_Book}) format('truetype')`,
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					input: {
						// See https://stackoverflow.com/questions/74683182/mui-textfield-component-changes-background-color-to-white-when-you-choose-one-of
						"&:-webkit-autofill": {
							WebkitBoxShadow: "0 0 0 100px #282728 inset",
							WebkitTextFillColor: "#e2e3e4",
						},
					},
					"& .MuiInputBase-input.Mui-disabled": {
						WebkitTextFillColor: "#e2e3e4",
					},
					"& .MuiOutlinedInput-root": {
						"& fieldset": {
							borderColor: "#6c6e70",
						},
						"&:hover fieldset": {
							borderColor: PLWhiteNew,
						},
						"&.Mui-focused fieldset": {
							borderColor: PLWhite,
						},
						"&.Mui-disabled fieldset": {
							borderColor: "#555555",
						},
					},
					"& .MuiInputLabel-root": {
						color: PLWhite,
					},
					"& .MuiInputLabel-asterisk": {
						transition: "color 0.2s ease",
					},
					"&:focus-within .MuiInputLabel-asterisk": {
						color: PLWhite,
					},
					"& .MuiInputLabel-root.Mui-focused": {
						color: PLWhite,
					},
					"& .MuiInputLabel-root.Mui-disabled": {
						color: '#555555',
					},
				},
			},
			defaultProps: {
				InputLabelProps: {
					shrink: true,
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				icon: {
					color: PLWhite,
				},
				root: {
					"& .MuiOutlinedInput-root": {
						"& fieldset": {
							borderColor: PLWhiteNew,
						},
						"&:hover fieldset": {
							borderColor: PLWhiteNew,
						},
						"&.Mui-focused fieldset": {
							borderColor: PLWhite,
						},
					},
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: '#6c6e70',
					'&.Mui-checked': {
						color: 'primary.contrastText',
					},
				},
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				loading: {
					color: 'white',
				},
				noOptions: {
					color: 'white',
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: PLWhite,
					"&.Mui-focused": {
						color: PLWhite,
					},
					"&.Mui-disabled": {
						color: "#555555",
					},
				},
			},
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					color: '#6c6e70',
					'&.Mui-checked': {
						color: PLRed,
					},
					'&.Mui-disabled': {
						color: '#555555',
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: PLWhite,
					"&.Mui-focused": {
						color: PLWhite,
					},
					"&.Mui-disabled": {
						color: "#555555",
					},
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: PLGreyNew,
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					backgroundColor: PLDkgGrey,
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					backgroundColor: PLDkgGrey,
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					color: "#aaaaaa",
					"&.Mui-disabled": {
						color: "#555555",
					},
					"&:hover": {
						color: "#ffffff",
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					color: "white",
					backgroundColor: "#282728",
					border: "1px solid #6c6e70",
					"&.Mui-disabled": {
						color: "#555555",
					},
					textTransform: "none",
					fontSize: "1rem",
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: "none",
					fontSize: "1rem",
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				root: {
					textTransform: "none",
					fontSize: "1rem",
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					backgroundColor: "#6c6e70",
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					border: "1px solid #383838",
				},
			},
		},
		MuiDayCalendar: {
			styleOverrides: {
				weekDayLabel: {
					color: "#aaaaaa",
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: "#aaaaaa",
				},
			},
		}
	},
});

export default darkTheme;
